import React, { Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Buttons from '../../../components/ui/button/Buttons'
import Image from '../../../components/image/Image'
import MolGrid from '../../../components/Grid/MolGrid'
import { PaymentApprovalTableConfig, paymentFormData } from '../data/PaymentReconciliationTable.Data'
import { APIUrl } from '../../../utils/API/fetchBaseQuery'
import FormCreator from '../../../components/Forms/FormCreator'
import SwalAlert from '../../../services/swalService/SwalService'
import { useAcceptTotalMembershipPaidAmountInReconciliationMutation, useSavePaymentReconcilationDetailsByAdminMutation } from '../../../app/services/membershipFinancialService/MembershipFinancialServices'
import { useLazyGetPDFQuery } from '../../../app/services/Common/CommonApi'
import { saveAs } from 'file-saver';

const PaymentApproval = forwardRef((props, ref) => {
    const formRef = useRef();
    const [formData, SetFormData] = useState(paymentFormData);
    const [isValidateAmountCollected, setIsValidateAmountCollected] = useState(false);
    const molGridRef = useRef();
    const { error, success } = SwalAlert();
    const [savePaymentRecord, { data: addPaymentRecord, isSuccess: isSuccess, isLoading: isLoading, }] = useSavePaymentReconcilationDetailsByAdminMutation();
    const [acceptPayment, { data: acceptPaymentResponse, isSuccess: isAcceptSuccess, isLoading: isAcceptLoading, }] = useAcceptTotalMembershipPaidAmountInReconciliationMutation();
    const [getPDF, { isSuccess: isSuccessGetPDF, isLoading: isLoadingGetPDF, data: getPDFData }] = useLazyGetPDFQuery();
    // Expose the form data retrieval method to parent components using the forwarded ref
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const formResponse = formRef.current.getFormData();
                return formResponse;
            }
            return null;
        }
    }));

    useEffect(() => {
        if (!isLoading && isSuccess && addPaymentRecord) {
            success("", "Payment Record Add Successfully ")
            resetForm();
            props.closePopUp()
        }
    }, [isLoading, isSuccess, addPaymentRecord])

    useEffect(() => {
        if (!isAcceptLoading && isAcceptSuccess && acceptPaymentResponse) {
            success("", "All payments have been successfully completed.")
            props.closePopUp()
        }
    }, [isAcceptLoading, isAcceptSuccess, acceptPaymentResponse])
    const resetForm = () => {
        let form = { ...paymentFormData };
        form.initialState = {
            bankInformation: null,
            amountCollected: "",
            amountDate: "",
        }
        SetFormData(form);
    }
    const handlePaymentRecord = () => {
        let formResponse = formRef.current.getFormData();
        if (!formResponse) {
            return error("Please fill all mandatory fields.", "")
        }
        const request = {
            PaymentProofId: props?.selectedReconciliationRecord?.paymentProofId || null,
            BankInformationId: formResponse?.bankInformation?.value || formResponse?.bankInformation,
            AmountCollected: formResponse?.amountCollected || null,
            AmountCollectedDate: formResponse?.amountDate || null,
        }
        savePaymentRecord(request)
    }
    const handleAcceptPayment = () => {
        if (props.remaingAmount > 0) {
            return error("Please pay the remaining amount.")
        }
        let request = {
            CorporateMemberId: props?.selectedReconciliationRecord?.corporateMemberId,
            IndividualMemberId: props?.selectedReconciliationRecord?.individualMemberId,
            MembershipPaymentTypeId: props?.selectedReconciliationRecord?.membershipPaymentTypeId,
            MembershipPaymentId: props?.selectedReconciliationRecord?.bookingId,
            AcademicMembersId: props?.selectedReconciliationRecord?.academicMemberId,
        }
        acceptPayment(request);
    }
    const handleStateChange = (value, dataField) => {
        // const { reconciliationTotalAmount } = props.selectedReconciliationRecord;

        if (dataField === "amountCollected") {
            const amountCollected = Number(value?.amountCollected);
            // const totalAmount = Number(reconciliationTotalAmount);

            if (amountCollected <= props?.remaingAmount) {
                setIsValidateAmountCollected(true);
            } else {
                error("The collected amount is greater than the Remain amount. Please enter a valid amount.", "");
                setIsValidateAmountCollected(false);
            }
        }
    };

    const { comments, reconciliationTotalAmount, paymentProofStatus, filePath, corporateMemberId, individualMemberId, academicMemberId } = props.selectedReconciliationRecord
    const extension = filePath?.split('.')[1];
    const { paymentListArr, remaingAmount } = props
    let memberType = null;
    if (corporateMemberId !== null) {
        memberType = extension === 'pdf' ? 'CorporatePaymentProof' : 'corporatepaymentproofimage';
    } else if (individualMemberId !== null) {
        memberType = extension === 'pdf' ? 'IndividualPaymentProof' : 'individualpaymentproofimage';
    } else if (academicMemberId !== null) {
        memberType = extension === 'pdf' ? 'AcademicPaymentProof' : 'academicpaymentproofimage';
    }

    const handleDownload = async () => {
        try {
            let request = {
                type: memberType,
                fileName: filePath,
            };
            const response = await getPDF(request);
            if (response?.data?.fileData && response?.data?.fileName) {
                saveAs(response?.data?.fileData, response?.data?.fileName);
            }
        } catch (error) {
            error('Failed to download the PDF. Please try again later.', '');
        }
    };

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-6'>
                    <h3>{extension === "pdf" ? "PDF" : "Image"} Proof</h3>
                </div>
                <div className='col-lg-6 d-flex justify-content-end'>
                    <Buttons
                        buttonText="Download"
                        buttonTypeClassName="theme-btn"
                        onClick={handleDownload}
                    />
                </div>
            </div>
            <div className='row mt-4 d-flex justify-content-center mb-5'>
                {extension !== "pdf" && <div className='col-lg-7'>
                    <Suspense fallback={<p>Loading...</p>}>
                        <Image
                            alt="Profile Image"
                            imagePath={`${APIUrl}Common/getImage?type=${memberType}&fileName=${filePath}`}
                        />
                    </Suspense>
                </div>
                }
                <div className='col-lg-12 mt-3'>
                    <h3>Comments:{comments || ""}</h3>
                </div>
            </div>
            <hr />
            {remaingAmount > 0 && <div className='row mb-5 mt-5'>
                <FormCreator
                    ref={formRef} {...formData}
                    onFormDataChange={handleStateChange}
                />
                <div className='col-lg-6 mt-3'>
                    <p></p>
                    <Buttons
                        buttonText="Add"
                        buttonTypeClassName="theme-btn"
                        onClick={handlePaymentRecord}
                        isLoading={isLoading}
                        isDisable={!isValidateAmountCollected}
                    />
                </div>
            </div>
            }
            <div className='row mb-5 mt-5'>
                <div className='col-lg-6'>
                    <h3>Total Amount: {"$" + Number(reconciliationTotalAmount) || "$" + 0}</h3>
                </div>
                <div className='col-lg-6 d-flex justify-content-end'>
                    <h3>Amount to be Paid:{"$" + Number(remaingAmount) || "$" + 0}</h3>
                </div>
            </div>
            <MolGrid
                ref={molGridRef}
                configuration={PaymentApprovalTableConfig}
                dataSource={paymentListArr}
                allowPagination={false}
            />
            {(remaingAmount === 0 && paymentProofStatus !== "Approved") && <div className='row mt-5'>
                <div className='col-lg-12 d-flex justify-content-end'>
                    <Buttons
                        buttonText="Accept"
                        buttonTypeClassName="theme-btn"
                        isLoading={isAcceptLoading}
                        onClick={handleAcceptPayment}
                    />
                </div>
            </div>
            }
        </div >
    )
})
export default PaymentApproval