import { forwardRef, React, useImperativeHandle, useRef, useEffect, useState } from 'react'
import FormCreator from '../../../../components/Forms/FormCreator';
import { useLazyGetPDFQuery, useStoreBase64ImageMutation, useUploadPDFMutation } from '../../../../app/services/Common/CommonApi.js';
import ToastService from '../../../../services/toastService/ToastService.js';
import SwalAlert from '../../../../services/swalService/SwalService.js';
import { saveAs } from 'file-saver';
import Buttons from '../../../../components/ui/button/Buttons.js';


const ProfessionalCertificationDetails = forwardRef(({ formData, editProfessionalCertificate, ActionHandler }, ref) => {

    const [uploadImageName, setUploadImageName] = useState("");
    const [certificationFormData, setCertificationFormData] = useState(formData)
    const personalInformationFormRef = useRef();

    // Upload image api call
    const { error, success } = SwalAlert();
    const [saveMainImage,
        { isSuccess: isSuccessImage,
            isLoading: isLoadingImage,
            data: paymentProofImageUpload }
    ] = useStoreBase64ImageMutation();
    const [getPDF, { isSuccess: isSuccessGetPDF, isLoading: isLoadingGetPDF, data: getPDFData }] = useLazyGetPDFQuery();

    // Upload pdf api method
    const [uploadPDF,
        { isSuccess: isUploadSuccess,
            isLoading: isUploadLoading,
            data: fileUploadData }
    ] = useUploadPDFMutation();

    const handleOnChangeFileUpload = (dataField, data) => {
        let pathName = "professionalInformationFile";
        let Data = new FormData();
        Data.append("file", data)
        Data.append("storagePath", pathName);
        uploadPDF(Data);
    }
    const handleImageUpload = async (data) => {

        const { base64Data, fileName } = data
        let saveFileRequest = {
            fileName: fileName,
            fileStr: base64Data,
            storagePath: "ProfessionalInformationImage",
        }
        setUploadImageName(fileName);
        const response = await saveMainImage(saveFileRequest)
    }

    const handleFormFieldChange = (dataField, value) => {

        if ((dataField === "professionalInformationFile") && value) {

            if (value.type === "application/pdf") {
                handleOnChangeFileUpload(dataField, value)
            }
            else {
                handleImageUpload(value)
            }
        }
    };

    useEffect(() => {
        if (formData)
            setCertificationFormData(formData)
    }, [formData])

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const data = personalInformationFormRef.current.getFormData();
                return data;
            }
            return null;
        }
    }));

    const handleClear = () => { }
    const handleDownload = async (rowData) => {

        try {
            let fileNameParts = rowData.professionalInformationSecurityCertificatesFileName.split('.');
            let fileExtension = fileNameParts.pop().toLowerCase();
            let fileNameWithoutExtension = fileNameParts.join('.');

            if (fileExtension === 'pdf' || fileExtension === 'jpg') {
                let request = {
                    type: "",
                    fileName: rowData.professionalInformationSecurityCertificatesFileName,
                };
                if (fileExtension === 'pdf') {
                    request.type = "ProfessionalCertification";
                } else if (fileExtension === 'jpg') {
                    request.type = "ProfessionalInformationImage";
                }
                const response = await getPDF(request);
                if (response?.data?.fileData && response?.data?.fileName) {
                    saveAs(response?.data?.fileData, response?.data?.fileName);
                }
            }
        } catch (error) {
            console.error('Failed to download the PDF. Please try again later.', error);
        }
    };

    const handleTableDelete = (rowData) => {
        ActionHandler['DELETE'](rowData, "ProfessionalCertification")
    }
    const handleTableEdit = (rowData) => {
        ActionHandler['EDIT'](rowData, "ProfessionalCertification")
    }
    const handleAdd = () => {
        ActionHandler['ADD'](formData.initialState, "ProfessionalCertification")
    }
    const handleTableDownload = (rowData) => {
        ActionHandler['DOWNLOAD'](rowData, "ProfessionalCertification")
    }
    const handleUpdate = () => {
        ActionHandler['UPDATE'](formData.initialState, "ProfessionalCertification")
    }

    // // Form action handler to manage different form actions
    // const formActionHandler = {
    //     // CLEAR: handleClear,
    //     DOWNLOAD: handleChangeFileDownload,
    // }
    useEffect(() => {
        if (!isUploadLoading && isUploadSuccess && fileUploadData) {
        }
    }, [isUploadSuccess, isUploadLoading, fileUploadData,]);
    useEffect(() => {
        if (!isLoadingImage && isSuccessImage && paymentProofImageUpload) {
            ToastService.success("Image upload successfuly.")
        }
    }, [isLoadingImage, isSuccessImage, paymentProofImageUpload,]);

    return (
        <div className="form-field-sec">
            <div className="row gx-5">
                <FormCreator
                    ref={personalInformationFormRef}
                    {...certificationFormData}
                    //onActionChange={formActionHandler}
                    handleTableEdit={handleTableEdit}
                    handleTableDelete={handleTableDelete}
                    handleTableDownload={handleDownload}
                    onFormFieldValueChange={handleFormFieldChange}
                ></FormCreator>
            </div>
            {editProfessionalCertificate ? <Buttons
                buttonTypeClassName="theme-btn ml-5"
                onClick={handleUpdate}
                buttonText={"Update"}
            /> : <Buttons
                buttonTypeClassName="theme-btn ml-5"
                onClick={handleAdd}
                buttonText={"Add"}
            />
            }
        </div>
    )
})

export default ProfessionalCertificationDetails