import { forwardRef, React, useImperativeHandle, useRef, useState } from 'react'
import AccountInformationData from "./data/AccountInformation.data";
import FormCreator from '../../../../components/Forms/FormCreator';

const AccountInformation = forwardRef(({ formData }, ref) => {
  const accountInformationFormRef = useRef();


  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = accountInformationFormRef.current.getFormData();
        return data;
      }
      return null;
    }
  }));

  return (
    <div className="form-field-sec">
      <div className="row gx-5">
        <FormCreator
          ref={accountInformationFormRef}
          {...formData}
        ></FormCreator>
      </div>
    </div>

  )
}
)
export default AccountInformation