import React, { useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccountInformation from './component/accountInformation/AccountInformation';
import MembershipSubscription from './component/membershipSubscription/MembershipSubscription';
import InstitutionDetails from './component/institutionDetails/InstitutionDetails';
import PointOfContact from './component/pointOfContact/PointOfContact';
import Buttons from '../../components/ui/button/Buttons';
import { useNavigate, useParams } from 'react-router-dom';
import { decryptAESID } from '../../utils/Encryption';
import { useLazyGetAllDropdownsForAcademicMembersQuery } from '../../app/services/Academic/AcademicTaskListApi';
import { setViewAcadmicMemberDropDown } from './utils/setViewAcadmicMemberDropDown';
import { useLazyGetAcadmicMemberDetailByIdQuery, useLazyGetAcadmicSIISAccountInfoQuery, useUpdateAcademicMemberByIdMutation } from '../../app/services/Academic/ViewAcadmicMemberApi';
import PointOfContactData from './component/pointOfContact/data/PointOfContact.data';
import MembershipSubscriptionData from './component/membershipSubscription/data/MembershipSubscription.data';
import InstitutionDetailsData from './component/institutionDetails/data/InstitutionDetails.data';
import AccountInformationData from './component/accountInformation/data/AccountInformation.data';
import ToastService from '../../services/toastService/ToastService';
import SwalAlert from '../../services/swalService/SwalService';
import { saveAs } from 'file-saver';
import FinancialRecord from "../../features/viewIndividualMember/component/finacialRecord/FinancialRecord";
// Import API services
import { useUploadPDFMutation, useLazyGetPDFQuery } from '../../app/services/Common/CommonApi';

const EditAcademicMembersForm = () => {
  const navigate = useNavigate()
  const { error } = SwalAlert();
  const { id } = useParams();
  const acadmicMemberId = decryptAESID(id)
  const [accountInformationForm, setAccountInformationForm] = useState(AccountInformationData);
  const [membershipSubscriptionForm, setMembershipSubscriptionForm] = useState(MembershipSubscriptionData);
  const [institutionDetailsForm, setInstitutionDetailsForm] = useState(InstitutionDetailsData);
  const [pointOfContactForm, setPointOfContactForm] = useState(PointOfContactData);
  const [isAcademicMember, setIsAcademicMember] = useState(true);
  const [acraFileName, setACRAFileName] = useState(null);
  const accountInformationRef = useRef()
  const membershipSubscriptionRef = useRef()
  const institutionDetailsRef = useRef()
  const pointOfContactFRef = useRef()

  useEffect(() => {
    getMemberDetails(acadmicMemberId);
    getAcadmicSIISAccountInfo(acadmicMemberId);
  }, [acadmicMemberId])

  useEffect(() => {
    getAllDropDowns()
  }, []);

  const [getAllDropDowns, {
    data: dropdownResponse,
    isSuccess: dropdownIsSuccess,
    isLoading: dropdownIsLoading,
  }] = useLazyGetAllDropdownsForAcademicMembersQuery();


  const [getMemberDetails, {
    data: getMemberDetailsData,
    isSuccess: getMemberDetailsIsSuccess,
    isLoading: getMemberDetailsIsLoading,
  }] = useLazyGetAcadmicMemberDetailByIdQuery();

  const [getAcadmicSIISAccountInfo, {
    data: getAcadmicSIISAccountInfoData,
    isSuccess: getAcadmicSIISAccountInfoIsSuccess,
    isLoading: getAcadmicSIISAccountInfoIsLoading,
  }] = useLazyGetAcadmicSIISAccountInfoQuery();

  const [updateAcademicMemberById, {
    data: updateAcademicMemberByIdData,
    isSuccess: updateAcademicMemberByIdIsSuccess,
    isLoading: updateAcademicMemberByIdIsLoading,
  }] = useUpdateAcademicMemberByIdMutation();

  const [uploadPDF,
    { isSuccess: isUploadSuccess,
      isLoading: isUploadLoading,
      data: fileUploadData }
  ] = useUploadPDFMutation();

  const [getPDF,
    { isSuccess: isSuccessGetPDF,
      isLoading: isLoadingGetPDF,
      data: getPDFData }
  ] = useLazyGetPDFQuery();

  useEffect(() => {
    if (!isUploadSuccess && isUploadLoading && fileUploadData) {
      ToastService.success("PDF uploaded successfully !")
    }
  }, [isUploadSuccess, isUploadLoading, fileUploadData,]);


  useEffect(() => {

    if (!updateAcademicMemberByIdIsLoading && updateAcademicMemberByIdIsSuccess && updateAcademicMemberByIdData) {
      ToastService.success("Update Successfully")
      getMemberDetails(acadmicMemberId);
    }
  }, [updateAcademicMemberByIdIsLoading, updateAcademicMemberByIdIsSuccess, updateAcademicMemberByIdData])

  useEffect(() => {
    if (!getAcadmicSIISAccountInfoIsLoading && getAcadmicSIISAccountInfoIsSuccess && getAcadmicSIISAccountInfoData) {
      setAccountInformationFormInitials();
    }
  }, [getAcadmicSIISAccountInfoIsLoading, getAcadmicSIISAccountInfoIsSuccess, getAcadmicSIISAccountInfoData])


  useEffect(() => {

    if (!getMemberDetailsIsLoading && getMemberDetailsIsSuccess && getMemberDetailsData) {
      setACRAFileName(getMemberDetailsData?.acraBizFileName)
      setMembershipSubscriptionFormInitials()
      setInstitutionDetailsFormFormInitials()
      setPointOfContactFormInitials()
    }
  }, [getMemberDetailsIsLoading, getMemberDetailsIsSuccess, getMemberDetailsData])

  useEffect(() => {
    if (!dropdownIsLoading && dropdownIsSuccess && dropdownResponse) {
      setViewAcadmicMemberDropDown(dropdownResponse);
    }
  }, [dropdownIsLoading, dropdownIsSuccess, dropdownResponse])

  const setAccountInformationFormInitials = () => {
    let temp = { ...accountInformationForm }
    let tmepInitials = temp.initialState;
    tmepInitials.accountStatus = getAcadmicSIISAccountInfoData?.accountStatus;
    tmepInitials.reasonForLeaving = getAcadmicSIISAccountInfoData?.deletionReasonId;
    tmepInitials.joinedDate = getAcadmicSIISAccountInfoData?.academicJoinedDate;
    tmepInitials.updatedDate = getAcadmicSIISAccountInfoData?.updatedAt;
    tmepInitials.updatedBy = getAcadmicSIISAccountInfoData?.updatedBy;
    tmepInitials.isAcademicQRScan = getAcadmicSIISAccountInfoData?.isQRScan;
    setAccountInformationForm(temp);
  }
  const setMembershipSubscriptionFormInitials = () => {
    let temp = { ...membershipSubscriptionForm }
    let tmepInitials = temp.initialState;
    tmepInitials.membershipType = getMemberDetailsData.membershipTypeId;
    tmepInitials.membershipTenure = getMemberDetailsData.membershipTenureId;
    tmepInitials.preferredStartDate = getMemberDetailsData.startMembershipOn;
    tmepInitials.membershipStartDate = getMemberDetailsData.subscriptionStartDate;
    tmepInitials.membershipEndDate = getMemberDetailsData.subscriptionEndDate;
    tmepInitials.billingCategory = getMemberDetailsData.academicMemberBillingCategoryId;
    setMembershipSubscriptionForm(temp);
  }
  const setInstitutionDetailsFormFormInitials = () => {
    let temp = { ...institutionDetailsForm }
    let tmepInitials = temp.initialState;
    tmepInitials.nameOfInstitutionAsinAcra = getMemberDetailsData.organisationName;
    tmepInitials.uenNo = getMemberDetailsData.uenNo;
    tmepInitials.institutionAddressLine1 = getMemberDetailsData.addressLine1;
    tmepInitials.institutionAddressLine2 = getMemberDetailsData.addressLine2;
    tmepInitials.institutionAddressLine3 = getMemberDetailsData.addressLine3;
    tmepInitials.postalCode = getMemberDetailsData.postalCode;
    tmepInitials.acraBizFile = getMemberDetailsData.acraBizFileName;
    setInstitutionDetailsForm(temp);
  }
  const setPointOfContactFormInitials = () => {

    let temp = { ...pointOfContactForm }
    let tmepInitials = temp.initialState;
    tmepInitials.salutationsID = getMemberDetailsData.salutationId;
    tmepInitials.firstName = getMemberDetailsData.nameOfContactPerson;
    tmepInitials.lastName = getMemberDetailsData.lastName;
    tmepInitials.contactNumber = getMemberDetailsData.contactNo;
    tmepInitials.designation = getMemberDetailsData.designation;
    tmepInitials.joblevelId = getMemberDetailsData.jobLevelId;
    tmepInitials.emailAddress = getMemberDetailsData.email;
    setPointOfContactForm(temp);
  }

  const onBackButton = () => {
    navigate("/viewAcademicMembers")
  }
  const saveButton = () => {
    const accountInformationData = accountInformationRef.current.getFormData();
    const membershipSubscriptionData = membershipSubscriptionRef.current.getFormData();
    const institutionDetailsData = institutionDetailsRef.current.getFormData();
    const pointOfContactFData = pointOfContactFRef.current.getFormData();
    if (membershipSubscriptionData && institutionDetailsData && pointOfContactFData) {
      const request = {
        AcademicMembersId: acadmicMemberId,
        IsQRScan: accountInformationData.isAcademicQRScan,
        InstitutionName: institutionDetailsData.nameOfInstitutionAsinAcra,
        UENNo: institutionDetailsData.uenNo,
        AddressLine1: institutionDetailsData.institutionAddressLine1,
        AddressLine2: institutionDetailsData.institutionAddressLine2,
        AddressLine3: institutionDetailsData.institutionAddressLine3,
        PostalCode: institutionDetailsData.postalCode,
        ACRABizFileName: fileUploadData?.fileName ? fileUploadData.fileName : institutionDetailsData.acraBizFile,

        MembershipTypeId: membershipSubscriptionData.membershipType?.value ? membershipSubscriptionData.membershipType.value : membershipSubscriptionData.membershipType,
        MembershipTenureId: membershipSubscriptionData.membershipTenure?.value ? membershipSubscriptionData.membershipTenure.value : membershipSubscriptionData.membershipTenure,
        StartDate: membershipSubscriptionData.preferredStartDate,
        SubscriptionStartDate: membershipSubscriptionData.membershipStartDate,
        SubscriptionEndDate: membershipSubscriptionData.membershipEndDate,

        SalutationId: pointOfContactFData?.salutationsID.value ? pointOfContactFData?.salutationsID.value : pointOfContactFData?.salutationsID,
        NameOfContactPerson: pointOfContactFData?.firstName,
        LastName: pointOfContactFData?.lastName,
        ContactNo: pointOfContactFData?.contactNumber,
        Designation: pointOfContactFData?.designation,
        JobLevelId: pointOfContactFData?.joblevelId.value ? pointOfContactFData?.joblevelId.value : pointOfContactFData?.joblevelId,
        Email: pointOfContactFData?.emailAddress,

      }
      updateAcademicMemberById(request)
    }
    else {
      error("Fill in all required fields")
    }
  }
  const handleOnChangeFileUpload = (dataField, data) => {
    let pathName = dataField === "acraBizFile" && "ACRA_FILE";
    let formData = new FormData();
    formData.append("file", data)
    formData.append("storagePath", pathName);
    uploadPDF(formData);
  }
  const handleChangeFileDownload = async () => {
    let request = {
      type: "ACRA_FILE",
      fileName: fileUploadData?.fileName || acraFileName,
    }
    const response = await getPDF(request);
    saveAs(response?.data?.fileData, response?.data?.fileName);
  }

  const accordionData = [
    {
      id: 'accountInformation',
      header: 'Account Information',
      body: <AccountInformation
        formData={accountInformationForm}
        ref={accountInformationRef}
      />
    },
    {
      id: 'membershipSubscription',
      header: 'Membership Subscription',
      body: <MembershipSubscription
        formData={membershipSubscriptionForm} ref={membershipSubscriptionRef} />
    },
    {
      id: 'institutionDetails',
      header: 'Institution Details',
      body: <InstitutionDetails
        formData={institutionDetailsForm}
        ref={institutionDetailsRef}
        handleOnChangeFileUpload={handleOnChangeFileUpload}
        handleChangeFileDownload={handleChangeFileDownload} />
    },
    {
      id: 'pointOfContact',
      header: 'Point of Contact',
      body: <PointOfContact formData={pointOfContactForm} ref={pointOfContactFRef} />
    },
    {
      id: 'financialRecord',
      header: 'Financial Record',
      body: <FinancialRecord
        academicMemberId={acadmicMemberId}
        isAcademicMember={isAcademicMember}
      />
    }
  ];

  return (
    <>
      <div className='common-accordian-view-design'>
        <Accordion defaultActiveKey={["0"]} alwaysOpen>
          {accordionData.map(item => (
            <Accordion.Item key={item.id} eventKey={item.id}>
              <Accordion.Header>{item.header}</Accordion.Header>
              <Accordion.Body>{item.body}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <div className="mb-3">
            <Buttons
              buttonText="Back"
              buttonTypeClassName="back-btn"
              onClick={onBackButton}
            />
          </div>
          <div className="mb-3">
            <Buttons
              buttonText="Save"
              buttonTypeClassName="theme-btn"
              onClick={saveButton}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default EditAcademicMembersForm;
