import React from "react";
import EmailManagement from "../../pages/emailManagement/EmailManagement";
import ViewAcademicMembers from "../../pages/viewAcademicMembers/ViewAcademicMembers";
import EditAcademicMembers from "../../pages/viewAcademicMembers/EditAcademicMembers";
import EditCorporateMembers from "../../pages/viewCorporateMembers/EditCorporateMembers";
import ViewCorporateMembersForm from "../../features/viewCorporateMember/ViewCorporateMembersForm";
import ViewIndividualMember from "../../pages/viewIndividualMembers/ViewIndividualMember";
import EditIndividualMembers from "../../pages/viewIndividualMembers/EditIndividualMembers";
import CorporateMemberTaskList from "../../pages/corporateMemberTaskList/CorporateMemberTaskList";
import AcademicMemberTaskList from "../../pages/academicMemberTaskList/AcademicMemberTaskList";
import IndividualMemberTaskList from "../../pages/individualMemberTaskList/IndividualMemberTaskList";
import EmailCampaign from "../../pages/emailCapaign/EmailCampaign";
import AcademicMember from "../../pages/academicMemberTaskList/AcademicMember";
import CorporateMember from "../../pages/corporateMemberTaskList/CorporateMember";
import IndividualMember from "../../pages/individualMemberTaskList/IndividualMember";
import MembershipPricing from "../../pages/gmap/membershipPricingTable/MembershipPricing";
import PaymentReconciliation from "../../pages/financial/paymentReconciliation/PaymentReconciliation";
import EditEmailCampaign from "../../pages/emailCapaign/EditEmailCampaign";
import EmailTemplateManagement from "../../pages/emailTemplateManagement/EmailTemplateManagement";
import ViewEditAdminAccount from "../../pages/viewEditAdminAccount/ViewEditAdminAccount";
import CreateEmailTemplate from "../../pages/createEmailTemplate/CreateEmailTemplate";
import DropdownManagement from "../../pages/dropdownManagement/DropdownManagement";
import EditEmailTemp from "../../features/emailTemplateManagement/EditEmailTemp";
import CreateAdminAccount from "../../pages/createAdminAccount/CreateAdminAccount";
import EditAdminAccountForm from "../../features/editAdminAccount/EditAdminAccountForm";
import Report from "../../pages/report/Report";
import EDMUploadMember from "../../pages/eDM/EDMUploadMember";
const NotFound = React.lazy(() => import("../../pages/errors/NotFound"));
const Dashboard = React.lazy(() => import('../../pages/dashboard/Dashboard'));


export const ComponentNavigation = [
  {
    id: "pageNotFound",
    path: "pageNotFound",
    exact: true,
    title: "",
    component: NotFound,
    text: "Page not found",
    securityKey: ""
  },
  {
    id: 'dashboard',
    path: '/dashboard',
    exact: true,
    title: 'Dashboard',
    component: Dashboard,
    hasParams: false,
    text: 'Dashboard',
    securityKey: ""
  },
  {
    id: 'emailManagement',
    path: '/emailManagement',
    exact: true,
    title: 'Email Management',
    component: EmailManagement,
    hasParams: false,
    text: 'Email Management',
    securityKey: ""
  },

  {
    id: 'addCampaign',
    path: '/AddCampaign',
    exact: true,
    title: 'Add Campaign',
    component: EmailCampaign,
    hasParams: false,
    text: 'Add Campaign',
    securityKey: ""
  },
  {
    id: 'editCampaign',
    path: '/EditCampaign/:id',
    exact: true,
    title: 'Edit Campaign',
    component: EditEmailCampaign,
    hasParams: false,
    text: 'Edit Campaign',
    securityKey: ""
  },
  {
    id: 'corporateMember',
    path: '/corporateMember/:id',
    exact: true,
    title: 'Membership Task List (Corporate)',
    component: CorporateMember,
    hasParams: false,
    text: 'Membership Task List (Corporate)',
    securityKey: ""
  },
  {
    id: 'membershipTaskListCorporate',
    path: '/membershipTaskListCorporate',
    exact: true,
    title: 'Membership Task List (Corporate)',
    component: CorporateMemberTaskList,
    hasParams: false,
    text: 'Membership Task List (Corporate)',
    securityKey: ""
  },
  {
    id: 'academicMember',
    path: '/academicMember/:id',
    exact: true,
    title: 'Membership Task List (Academic)',
    component: AcademicMember,
    hasParams: false,
    text: 'Membership Task List (Academic)',
    securityKey: ""
  },
  {
    id: 'membershipPricing',
    path: '/membershipPricing',
    exact: true,
    title: 'Pricing Table',
    component: MembershipPricing,
    hasParams: false,
    text: 'Pricing Table',
    securityKey: ""
  },
  {
    id: 'membershipTaskListAcademic',
    path: '/membershipTaskListAcademic',
    exact: true,
    title: 'Membership Task List (Academic)',
    component: AcademicMemberTaskList,
    hasParams: false,
    text: 'Membership Task List (Academic)',
    securityKey: ""
  },
  {
    id: 'individualMember',
    path: '/individualMember/:id',
    exact: true,
    title: 'Membership Task List (Individual)',
    component: IndividualMember,
    hasParams: false,
    text: 'Membership Task List (Individual)',
    securityKey: ""
  },
  {
    id: 'membershipTaskListIndividual',
    path: '/membershipTaskListIndividual',
    exact: true,
    title: 'Membership Task List (Individual)',
    component: IndividualMemberTaskList,
    hasParams: false,
    text: 'Membership Task List (Individual)',
    securityKey: ""
  },
  // View Member start
  {
    id: 'viewAcademicMembers',
    path: '/viewAcademicMembers',
    exact: true,
    title: 'View Academic Member',
    component: ViewAcademicMembers,
    hasParams: false,
    text: 'View Academic Member',
    securityKey: ""
  },
  {
    id: 'EditAcademicMembers',
    path: '/editAcademicMembers/:id',
    exact: true,
    title: 'Edit Academic Members',
    component: EditAcademicMembers,
    hasParams: false,
    text: 'EditAcademicMembers',
    securityKey: ""
  },
  {
    id: 'viewCorporateMembers',
    path: '/viewCorporateMembers',
    exact: true,
    title: 'View Corporate Member',
    component: ViewCorporateMembersForm,
    hasParams: false,
    text: 'View Corporate Member',
    securityKey: ""
  },
  {
    id: 'EditCorporateMembers',
    path: '/editCorporateMembers/:id',
    exact: true,
    title: 'Edit Corporate Member',
    component: EditCorporateMembers,
    hasParams: false,
    text: 'EditCorporateMember',
    securityKey: ""
  },
  {
    id: 'viewIndividualMembers',
    path: '/viewIndividualMembers',
    exact: true,
    title: 'View Individual Member',
    component: ViewIndividualMember,
    hasParams: false,
    text: 'View Individual Member',
    securityKey: ""
  },
  {
    id: 'EditIndividualMembers',
    path: '/editIndividualMembers/:id',
    exact: true,
    title: 'Edit Individual Member',
    component: EditIndividualMembers,
    hasParams: false,
    text: 'EditIndividualMembers',
    securityKey: ""
  },
  {
    id: 'paymentReconciliation',
    path: '/paymentReconciliation',
    exact: true,
    title: 'Payment Reconciliation',
    component: PaymentReconciliation,
    hasParams: false,
    text: 'PaymentReconciliation',
    securityKey: ""
  },
  // Field Maintenance dropdown 
  {
    id: 'editAccountInformation',
    path: '/ViewEditAdminAccount/:id',
    exact: true,
    title: 'Edit Account Information',
    component: EditAdminAccountForm,
    hasParams: false,
    text: 'Edit Account Information',
    securityKey: ""
  },
  {
    id: 'editEmailTemplateManagement',
    path: '/EmailTemplateManagement/:id',
    exact: true,
    title: 'Edit Email Template',
    component: EditEmailTemp,
    hasParams: false,
    text: 'Edit Email Template',
    securityKey: ""
  },
  {
    id: 'emailTemplateManagement',
    path: '/EmailTemplateManagement',
    exact: true,
    title: 'Email Template Management',
    component: EmailTemplateManagement,
    hasParams: false,
    text: 'Email Template Management',
    securityKey: ""
  },
  {
    id: 'createEmailTemplate',
    path: '/CreateEmailTemplate',
    exact: true,
    title: 'Create Email Template',
    component: CreateEmailTemplate,
    hasParams: false,
    text: 'Create Email Template',
    securityKey: ""
  },
  {
    id: 'createEmailTemplate',
    path: '/CreateEmailTemplate',
    exact: true,
    title: 'Create Email Template',
    component: CreateEmailTemplate,
    hasParams: false,
    text: 'Create Email Template',
    securityKey: ""
  },
  {
    id: 'dropdownManagement',
    path: '/DropdownManagement',
    exact: true,
    title: 'Dropdown Management',
    component: DropdownManagement,
    hasParams: false,
    text: 'Dropdown Management',
    securityKey: ""
  },

  // Account Management 
  {
    id: 'createAdminAccount',
    path: '/CreateAdminAccount',
    exact: true,
    title: 'Create Account',
    component: CreateAdminAccount,
    hasParams: false,
    text: 'Create Account',
    securityKey: ""
  },
  {
    id: 'ViewEditAdminAccount',
    path: '/ViewEditAdminAccount',
    exact: true,
    title: 'View/Edit Account',
    component: ViewEditAdminAccount,
    hasParams: false,
    text: 'View/Edit Account',
    securityKey: ""
  },
  {
    id: 'editAdminAccountForm',
    path: '/EditAdminAccountForm/:id',
    exact: true,
    title: 'Edit Admin Account',
    component: EditAdminAccountForm,
    hasParams: false,
    text: 'Edit Admin Account',
    securityKey: ""
  },
  {
    id: 'membershipReport',
    path: '/membershipReport',
    exact: true,
    title: 'Membership Report',
    component: Report,
    hasParams: false,
    text: 'membershipReport',
    securityKey: ""
  },
  {
    id: 'EDMUploadMember',
    path: '/EDMUploadMember',
    exact: true,
    title: 'Upload Mailing List',
    component: EDMUploadMember,
    hasParams: false,
    text: 'Upload Mailing List',
    securityKey: ""
  },
];