import { corporateFormData } from "../data/CorporateMemberTable.Data";

export const setFieldParameter = (apiResponse) => {
    corporateFormData.initialState.nameOfOrgACRA = apiResponse.organisationName;
    corporateFormData.initialState.uenNo = apiResponse.uneNo;
    corporateFormData.initialState.addressLine1 = apiResponse.addressLine1;
    corporateFormData.initialState.addressLine2 = apiResponse.addressLine2;
    corporateFormData.initialState.addressLine3 = apiResponse.addressLine3;
    corporateFormData.initialState.postalCode = apiResponse.postalCode;
    corporateFormData.initialState.industryCode = apiResponse.industryCodeId;
    corporateFormData.initialState.acraFile = apiResponse.acraBizFileName;
    corporateFormData.initialState.membershipTypeId = apiResponse.membershipTypeId;
    corporateFormData.initialState.bilingCategory = apiResponse.corporateMemberBillingCategoryId;
    corporateFormData.initialState.updatedBy = apiResponse.nameOfContactPerson;
    corporateFormData.initialState.subscriptionStartDate = apiResponse.subscriptionStartDate;
    corporateFormData.initialState.subscriptionEndDate = apiResponse.subscriptionEndDate;
    corporateFormData.initialState.lastUpdatedDate = apiResponse.updatedDate;
    corporateFormData.initialState.isStartMembershipOn = apiResponse.isStartMemberShip;
    corporateFormData.initialState.startMembershipDate = apiResponse.startMembershipOn;
    corporateFormData.initialState.pricingSubscriptionEndDate = '';
    corporateFormData.initialState.paymentTerms = apiResponse.paymentTermsId || 1;
    corporateFormData.initialState.membershipTenureId = apiResponse.membershipTenureId;
    corporateFormData.initialState.complimentary = apiResponse.complimentaryCorporateMembership;
    corporateFormData.initialState.commentsToMember = apiResponse.commentstomember;
    corporateFormData.initialState.internalNotes = apiResponse.internalNotes;

    const navigatePathIndex = corporateFormData.formFields.findIndex(field => field.id === "navigatePath");
    if (navigatePathIndex !== -1) {
        const updatedFormData = { ...corporateFormData };
        // Update the containerCss value for periodTo field
        updatedFormData.formFields[navigatePathIndex].fieldSetting.identifyNumber = apiResponse.corporateMembersId;
    }
};

export const setPricingDetailsField = (apiResponse, memberType) => {
    corporateFormData.initialState.entrenceFee = apiResponse.applicationFee || 0;
    corporateFormData.initialState.annualFee = apiResponse?.subTotal || 0; //apiResponse.membershipTenureId  === 1 ? apiResponse.firstYearFee || 0 : apiResponse.secondYearFee || 0;  // complimentaryindividualMembership
    corporateFormData.initialState.complimentary = apiResponse.complimentaryindividualMembership
    return corporateFormData
}

