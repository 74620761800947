import { FormFieldTypes } from "../../../../../data/formFieldType";

const IndividualAccountInformationData = {
    name: "IndividualAccountInformationData",
    initialState: {
        accountStatus: "",
        reasonForLeaving: "",
        joinedDate: "",
        updatedDate: "",
        updatedBy: "",
        isQRScan: false,
    },
    formFields: [
        {
            id: "accountStatus",
            lable: "Account Status",
            Field_Name: "Account Status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "accountStatus",
            fieldSetting: {
                options: [],
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "reasonForLeaving",
            lable: "Reason for Leaving",
            Field_Name: "Reason for Leaving",
            fieldType: FormFieldTypes.SELECT,
            dataField: "reasonForLeaving",
            fieldSetting: {
                allowSpace: true,
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "joinedDate",
            lable: "Joined Date",
            Field_Name: "Joined Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "joinedDate",
            fieldSetting: {
                // placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "updatedDate",
            lable: "Updated Date",
            Field_Name: "Updated Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "updatedDate",
            fieldSetting: {
                // placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "updatedBy",
            lable: "Updated by",
            Field_Name: "Updated by",
            fieldType: FormFieldTypes.SELECT,
            dataField: "updatedBy",
            fieldSetting: {
                // placeholder: "",
                allowSpace: true,
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "isQRScan",
            lable: "QR code has been set up",
            Field_Name: "QR code has been set up",
            fieldType: FormFieldTypes.TOGGLEINPUT,
            dataField: "isQRScan",
            fieldSetting: {
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
    ],
};
export default IndividualAccountInformationData;