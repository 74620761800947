import React, { Suspense, forwardRef, lazy, useEffect, useImperativeHandle, useRef, useState } from "react"
import FormCreator from "../../components/Forms/FormCreator";
import { inidividualFormData } from "./data/IndividualMemberTable.Data";
import Buttons from "../../components/ui/button/Buttons";
import { useNavigate, useParams } from "react-router-dom";
import { decryptAESID } from "../../utils/Encryption";
import SwalAlert from "../../services/swalService/SwalService";
import {
    useLazyGetAllDropdownsForIndividualMembersQuery,
    useLazyGetIndividualInformationDetailsByIdQuery,
    useGetMembershipPricingForMembershipTypeIdMutation,
    useRejectIndividualMemberApplicationMutation,
    useSaveIndividualMemberClarificationDetailsMutation,
    useSentPaymentLinkIndividualMemberMutation,
    useUpdateIndividualDataByIdMutation,
    useAddEditMembershipTaskAndSendForApprovalMutation,
    useApproveIndividualUpgradeMutation,
    useGetPricingForUpgradeIndividualMutation,
    useSendPaymentLinkForUpgradeIndividualMutation,
} from "../../app/services/Individual/IndividualTaskListApi";
import { setAllDropDownField, setAuditLogList } from "./utils/setAllDropDownField";
import CenterModel from "../../components/ui/centerModel/CenterModel";
import { saveAs } from 'file-saver';
import { useLazyGetPDFQuery, useUploadPDFMutation } from "../../app/services/Common/CommonApi";
import {
    setFieldParameter,
    setPricingDetailsField,
    setAuditLogField,
    changesWhenisUpgradeIndividualMember,
    onHandleNationalityDropdown,
} from "./utils/setEditFieldsUtils";
import ModuleLoader from "../../components/ui/moduleLoader/ModuleLoader";
import MolGrid from "../../components/Grid/MolGrid";
import { IndividualMemberDataContext } from "../../pages/individualMemberTaskList/IndividualMember";
import { getData } from "../../utils/LocalStorage/LocalStorageManager";
// import CKEditorComponent from "../../components/ui/inputs/ckEditor/CkEditor";
const TinyEditor = lazy(() => import('../../components/ui/inputs/TinyEditor/TinyEditor'));

const IndividualForm = forwardRef((props, ref) => {
    const { id } = useParams();
    const editID = id ? decryptAESID(id, false).toString() : 0;
    const { confirm, error, success } = SwalAlert();
    const formRef = useRef();
    const navigate = useNavigate();
    const auditLogNotesRef = useRef();
    const [formData, SetFormData] = useState(inidividualFormData);
    const [rejectComments, setRejectComments] = useState("");
    const [CFComments, setCFComments] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenForCF, setIsModalOpenForCF] = useState(false);
    const [taskListDetails, setTaskListDetails] = useState({})
    const [isSendPaymentLink, setIsSendPaymentLink] = useState(false);
    const [isUpdateTaskDetailsLink, setIsUpdateTaskDetailsLink] = useState("");
    const [membershipPricingDetails, setMembershipPricingDetails] = useState({
        membershipTypeId: 0,
        membershipTenureId: 0
    });
    const [acraFileName, setACRAFileName] = useState(null);
    const [memberTaskId, setMemberTaskId] = useState(0);
    const [getIndiviudalMembershipType, setIndividualMembershipType] = useState(0);
    const [isApprovalRequiredFlag, setIsApprovalRequired] = useState(null);
    const [isSendBackToPIC1Flag, setIsSendBackToPIC1Flag] = useState(null);
    const [isPIC1Falg, setIsPIC1Flag] = useState(null);
    const [IndividualInfo, setIndividualInfo] = useState({
        internalNotes: ''
    })
    // const [auditLogList, setAuditLogList] = useState([]);
    const contextValue = {
        IndividualInfo,
        setContextData: (data) =>
            setIndividualInfo(data)
    }
    const pricingInitialStateRef = useRef({});
    // Define API queries and mutations
    const [getAllDropDowns, { data: dropdownResponse, isSuccess: isSuccess, isLoading: isLoading, }] = useLazyGetAllDropdownsForIndividualMembersQuery();
    const [approveIndividualUpgrade, { data: approveIndividualUpgradeData, isSuccess: approveIndividualUpgradeIsSuccess, isLoading: approveIndividualUpgradeIsLoading, }] = useApproveIndividualUpgradeMutation();
    const [getMemberDetailsByID, { data: memberDetails, isSuccess: isSuccessGetByID, isLoading: isLoadingGetById, }] = useLazyGetIndividualInformationDetailsByIdQuery();
    const [updateMemberData, { data: updatedMemberDataResponse, isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate, }] = useUpdateIndividualDataByIdMutation();
    const [rejectApplication, { data: rejectApplicationResponse, sisSuccess: IsRejected, isLoading: IsRejectedLoading, }] = useRejectIndividualMemberApplicationMutation();
    const [uploadPDF, { data: fileUploadData, isSuccess: isUploadSuccess, isLoading: isUploadLoading, }] = useUploadPDFMutation();
    const [sendPaymentLink, { data: paymentResponse, isSuccess: isPaymentSuccess, isLoading: IsPaymentLoading, }] = useSentPaymentLinkIndividualMemberMutation();
    const [sendClearfication, { data: CFResponse, isSuccess: isCFSuccess, isLoading: IsCFLoading, }] = useSaveIndividualMemberClarificationDetailsMutation();
    const [getPricing, { data: pricingResponse, isSuccess: isSuccessPrice, isLoading: isLoadingPrice, }] = useGetMembershipPricingForMembershipTypeIdMutation();
    const [getPDF, { isSuccess: isSuccessGetPDF, isLoading: isLoadingGetPDF, data: getPDFData }] = useLazyGetPDFQuery();
    const [sentForAprroval, { isSuccess: isSuccessSentApproval, isLoading: isLoadingSentApproval, data: sentApprovalResponse }] = useAddEditMembershipTaskAndSendForApprovalMutation();
    //get update individual prising for isolated individual
    const [getPricingForUpgradeIndividual,
        {
            data: UpdateMembershipPricingResponse,
            isSuccess: isUpdateMembershipPricingsuccess,
            isLoading: UpdateMembershipPricingLoading,
        },
    ] = useGetPricingForUpgradeIndividualMutation();
    const [sendPaymentLinkForUpgradeIndividual,
        {
            data: sendPaymentLinkForUpgradeIndividualResponse,
            isSuccess: sendPaymentLinkForUpgradeIndividualSuccess,
            isLoading: sendPaymentLinkForUpgradeIndividualLoading,
        },
    ] = useSendPaymentLinkForUpgradeIndividualMutation();

    // Expose getFormData method to parent components using useImperativeHandle
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const formResponse = formRef.current.getFormData();
                return formResponse;
            }
            return null;
        }
    }));
    useEffect(() => {
        if (!sendPaymentLinkForUpgradeIndividualLoading && sendPaymentLinkForUpgradeIndividualSuccess && sendPaymentLinkForUpgradeIndividualResponse) {
            success("", "Payment Link Sent On Registed Email Successfully ").then(() => { navigate("/membershipTaskListIndividual") })
            setIsModalOpen(false);
            setIsSendPaymentLink(false);
        }
    }, [sendPaymentLinkForUpgradeIndividualLoading, sendPaymentLinkForUpgradeIndividualSuccess, sendPaymentLinkForUpgradeIndividualResponse])
    // Handle dropdown data fetch success
    useEffect(() => {
        if (!isLoading && isSuccess && dropdownResponse) {
            setAllDropDownField(dropdownResponse)
        }
    }, [isLoading, isSuccess, dropdownResponse])
    // Handle APPROVE data fetch success
    useEffect(() => {
        if (!approveIndividualUpgradeIsLoading && approveIndividualUpgradeIsSuccess && approveIndividualUpgradeData) {
            success("", "Application Approve Successfully").then(() => { navigate("/membershipTaskListIndividual") })
        }
    }, [approveIndividualUpgradeIsLoading, approveIndividualUpgradeIsSuccess, approveIndividualUpgradeData])
    // Handle member details fetch success
    useEffect(() => {
        if (!isLoadingGetById && isSuccessGetByID && memberDetails) {
            setFieldParameter(memberDetails);
            setTaskListDetails(memberDetails);
            setMemberTaskId(memberDetails.taskId);
            setACRAFileName(memberDetails?.acraBizFileName);
            setIndividualMembershipType(memberDetails.membershipTypeId);
            setIsApprovalRequired(memberDetails.isApprovalRequired);
            setAuditLogList(memberDetails.auditLogList);
            setAuditLogField(memberDetails);
            setIsSendBackToPIC1Flag(memberDetails.isSendBackToPIC1);
            setMembershipPricingDetails({ ...membershipPricingDetails, membershipTypeId: memberDetails?.membershipTypeId, membershipTenureId: memberDetails?.membershipTenureId });
            let data = {
                value: memberDetails.nationalityTypeId
            }
            if (memberDetails.isUpgradeUnderAppOrCpp && memberDetails.isUpgrade) {
                // let updatedForm = changesWhenisUpgradeUnderAppOrCpp(formData);
                let updatedForm2 = onHandleNationalityDropdown(data, formData);
                SetFormData(updatedForm2);
            }
            else if (memberDetails.isUpgrade !== true) {
                let updatedForm = changesWhenisUpgradeIndividualMember(formData)
                let updatedForm2 = onHandleNationalityDropdown(data, updatedForm);
                SetFormData(updatedForm2);
            }
            else {
                let updatedForm = onHandleNationalityDropdown(data, formData);
                SetFormData(updatedForm);
            }
        }
    }, [isLoadingGetById, isSuccessGetByID, memberDetails])
    // Handle update success
    useEffect(() => {
        if (!isLoadingUpdate && isSuccessUpdate && updatedMemberDataResponse && isUpdateTaskDetailsLink === "isUpdated") {
            if (isUpdateTaskDetailsLink === "isUpdated" && isSendPaymentLink) {
                setIsUpdateTaskDetailsLink("");
            }
            else {
                success("", "Application Updated Successfully ");
            }
        }
    }, [isLoadingUpdate, isSuccessUpdate, updatedMemberDataResponse]);
    // Handle rejection success
    useEffect(() => {
        if (!IsRejectedLoading && IsRejected && rejectApplicationResponse) {
            success("", "Application Reject Successfully ")
            setIsModalOpen(false)
        }
    }, [IsRejectedLoading, IsRejected, rejectApplicationResponse]);
    // Handle payment link success
    useEffect(() => {
        if (!IsPaymentLoading && isPaymentSuccess && paymentResponse) {
            success("", "Payment Link Sent On Registed Email Successfully ")
            setIsModalOpen(false);
            setIsSendPaymentLink(false);
            window.location.reload(false);
        }
    }, [IsPaymentLoading, isPaymentSuccess, paymentResponse]);
    // Handle clarification success
    useEffect(() => {
        if (!IsCFLoading && isCFSuccess && CFResponse) {
            success("", "Clarification Sent Successfully")
            setIsModalOpenForCF(false)
            setCFComments('');
        }
    }, [IsCFLoading, isCFSuccess, CFResponse]);
    // Handle pricing details fetch success
    useEffect(() => {
        if (!isLoadingPrice && isSuccessPrice && pricingResponse) {
            const response = setPricingDetailsField(pricingResponse);
            response.initialState.membershipTenureId = membershipPricingDetails.membershipTenureId
            response.initialState.membershipTypeId = membershipPricingDetails.membershipTypeId
            SetFormData(previousState => ({
                ...previousState,
                initialState: response.initialState
            })); // Ensure new object reference
            pricingInitialStateRef.current = {
                ...response.initialState
            };
        }
    }, [isLoadingPrice, isSuccessPrice, pricingResponse]);


    useEffect(() => {
        if (!UpdateMembershipPricingLoading, isUpdateMembershipPricingsuccess, UpdateMembershipPricingResponse) {

            const response = setPricingDetailsField(UpdateMembershipPricingResponse);
            response.initialState.membershipTenureId = membershipPricingDetails.membershipTenureId
            response.initialState.membershipTypeId = membershipPricingDetails.membershipTypeId
            SetFormData(previousState => ({
                ...previousState,
                initialState: response.initialState
            })); // Ensure new object reference
            pricingInitialStateRef.current = {
                ...response.initialState
            };
        }
    }, [UpdateMembershipPricingLoading, UpdateMembershipPricingResponse, isUpdateMembershipPricingsuccess]);
    // Get approve request response
    useEffect(() => {
        if (!isLoadingSentApproval && isSuccessSentApproval && sentApprovalResponse) {
            success("Approval request sent successfully!", "Successfull");
            getMemberDetailsByID(editID);
            window.location.reload(false);
        }
    }, [isLoadingSentApproval, isSuccessSentApproval, sentApprovalResponse]);
    // Initial data fetch
    useEffect(() => {
        getAllDropDowns()
        if (editID) {
            getMemberDetailsByID(editID);
        }
        let setPICFalg = getData("isPIC1")
        let isPICFlag = setPICFalg === true ? true : false;
        setIsPIC1Flag(isPICFlag);
        // hideShowAuditLog(isPICFlag);
    }, [])

    // Update pricing fields when membership details change
    useEffect(() => {
        if (membershipPricingDetails.membershipTypeId > 0 || membershipPricingDetails.membershipTenureId > 0) {
            setPricingField(membershipPricingDetails?.membershipTypeId, membershipPricingDetails?.membershipTenureId)
        }
    }, [membershipPricingDetails.membershipTypeId, membershipPricingDetails.membershipTenureId])

    // Handle form update
    const handleUpdate = (formData) => {
        let formResponse = formRef.current.getFormData() || formData;
        if (!formResponse) {
            return error("Please fill all mandatory fields.", "")
        }
        setIsUpdateTaskDetailsLink("isUpdated");
        const request = {
            IndividualMemberId: editID || 0,
            FirstName: formResponse?.fullName || null,
            SalutationId: formResponse?.salutationId?.value || formResponse?.salutationsID.value || formResponse?.salutationsID,
            LastName: formResponse?.lastName || null,
            ContactNo: formResponse?.contact || null,
            LikeUsToAddressYou: formResponse?.howWould || null,
            IsMale: formResponse?.isGenderMale === "male" ? true : false || false,
            NationalityTypeId: formResponse?.nationalityID?.value || formResponse?.nationalityID || null,
            CountryOfResidenceId: formResponse?.countryOfResID?.value || formResponse?.countryOfResID || null,
            ResidentStatusId: formResponse?.residentStatus?.value || formResponse?.residentStatus || null,
            RaceId: formResponse?.race?.value || formResponse?.race || null,
            BusinessEmail: formResponse?.bwEmailAddress || null,
            PersonalEmail: formResponse?.awEmailAddress || null,
            LinkedinProfile: formResponse?.linkedinProfile || null,
            MembershipTypeId: formResponse?.membershipTypeId?.value || memberDetails?.isUpgrade ? memberDetails?.currentMembershipType : formResponse?.membershipTypeId || null,
            IsStartMembershipOn: formResponse?.isStartMembershipOn || null,
            MembershipTenureId: formResponse?.membershipTenureId?.value || formResponse?.membershipTenureId || null,
            StartMembershipOn: formResponse?.startMembershipDate || null,
            PaymentTermsId: formResponse?.paymentTerms?.value || formResponse?.paymentTerms || null,
            Commentstomember: formResponse?.comment,
            SubscriptionEndDate: formResponse?.subscriptionEndDateForUpgrade || null
            //IsUpgrade: (memberDetails?.isUpgrade && !memberDetails?.isUpgradeUnderAppOrCpp) ? true : false,
        }
        updateMemberData(request);
    }
    // Toggle modal visibility for rejection
    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    }
    // Toggle modal visibility for clarification
    const openCFModal = () => {
        setIsModalOpenForCF(!isModalOpenForCF);
    }
    // Handle file field change
    const handleFormFieldChange = (dataField, value) => {
        if ((dataField === "acraFile") && value) {
            handleOnChangeFileUpload(dataField, value)
        }
    };
    // Handle file upload
    const handleOnChangeFileUpload = (dataField, data) => {
        let pathName = dataField === "acraFile" && "ACRA_FILE";
        let formData = new FormData();
        formData.append("file", data)
        formData.append("storagePath", pathName);
        uploadPDF(formData);
    }
    // Handle rejection of application
    const handleReject = () => {
        if (rejectComments === "" || rejectComments === undefined || rejectComments === null) {
            return error("The comments field for rejecting the application is mandatory and must be filled out.", "error")
        }
        const request = {
            IndividualMembersId: editID,
            IsRejected: true,
            SendRejectionReason: rejectComments,
        }
        confirm("Reject Application", "Are you sure you want to Reject Application?", "Yes", "Cancel")
            .then((confirmed) => {
                if (confirmed) {
                    rejectApplication(request);
                }
            });
    }
    // Set pricing field based on membership type and tenure
    const setPricingField = (memberType, memberTenure) => {
        if (memberDetails?.isUpgrade && !memberDetails?.isUpgradeUnderAppOrCpp) {
            const req = {
                membershipTenureId: memberTenure,
                individualMemberId: Number(editID),
                newMembershipType: memberType,
                oldMembershipType: memberDetails?.currentMembershipType,
            };
            getPricingForUpgradeIndividual(req)
        }
        else {
            let request = {
                MembershipTypeId: memberType,
                MembershipTenureId: memberTenure,
            }
            getPricing(request);
        }
    }
    // Handle send fro approval if mebership type is ordinary or associate
    const handleSentForApproval = () => {
        let formResponse = formRef.current.getFormData();
        if (!formResponse) {
            return error("Please fill all mandatory fields.", "")
        }
        const getPIC1Name = getData("pic1Name");
        let approvalRequest = {};
        approvalRequest.taskId = memberTaskId;
        approvalRequest.individualMemberId = Number(editID);
        // approvalRequest.companyName = formResponse;
        approvalRequest.firstName = formResponse.fullName;
        approvalRequest.lastName = formResponse.lastName;
        approvalRequest.email = formResponse.bwEmailAddress;
        approvalRequest.contactNo = formResponse.contact;
        approvalRequest.billingCategoryId = formResponse.billingCategory;
        approvalRequest.subscriptionStartDate = formResponse.subscriptionStartDate;
        approvalRequest.subscriptionEndDate = formResponse.subscriptionEndDate;
        approvalRequest.membershipTypeId = memberDetails?.isUpgrade ? memberDetails?.currentMembershipType : formResponse?.membershipTypeId;
        // approvalRequest.transactionTypeId = formResponse;
        approvalRequest.isApprovalRequired = true;
        approvalRequest.isPaymentLinkSent = false;
        approvalRequest.updatedBy = 1;
        approvalRequest.updatedAppId = 155;
        approvalRequest.commentsToMember = formResponse.comment;
        approvalRequest.internalNotes = IndividualInfo.internalNotes;
        approvalRequest.isRejected = false;
        // approvalRequest.membershipTermId = formResponse.;
        approvalRequest.pricingList = [];
        approvalRequest.isAppliedForRenewal = false;
        // approvalRequest.membershipTypeName = formResponse;
        approvalRequest.isPIC1 = isPIC1Falg;
        approvalRequest.updateByUser = getPIC1Name;
        approvalRequest.decision = "Approve";
        sentForAprroval(approvalRequest)
    }
    // Handle sending payment link
    const handleSentPaymentLink = () => {
        let formResponse = formRef.current.getFormData();
        if (!formResponse) {
            return error("Please fill all mandatory fields.", "")
        }
        setIsSendPaymentLink(true);
        handleUpdate(formResponse)
        if (memberDetails?.isUpgrade) {
            let gstAmount = parseFloat((((Number(formResponse.applicationFeePaing) + Number(formResponse.annualFeePaing)) / 100) * 9).toFixed(2));
            let subTotal = (Number(formResponse.applicationFeePaing) + Number(formResponse.annualFeePaing))
            let request = {
                IndividualMembersId: Number(editID),
                MembershipTypeId: formResponse?.membershipTypeId,
                MembershipTenureId: formResponse.membershipTenureId,
                EntranceFee: Number(formResponse.applicationFeePaing),
                AnnualFee: Number(formResponse.annualFeePaing),
                GSTAmount: gstAmount,
                SubTotalAmount: subTotal,
                TotalAmount: subTotal + gstAmount,
                GSTRate: 9,
                SubscriptionEndDate: formResponse?.subscriptionEndDateForUpgrade,
                TaskId: memberTaskId,
                InternalNotes: IndividualInfo.internalNotes,
                Decision: "Approve",
                IsPIC1: isPIC1Falg
            }
            sendPaymentLinkForUpgradeIndividual(request);
        }
        else {
            let request = {
                IndividualMembersId: Number(editID),
                IsSentPaymentLink: true,
                PaymentTermId: 1,
                IsStartMemberShip: formResponse?.isStartMembershipOn || null,
                StartMemberShipOn: formResponse?.startMembershipDate || null,
                MembershipTypeId: formResponse?.membershipTypeId,
                MembershipTenureId: formResponse.membershipTenureId,
                TaskId: memberTaskId,
                InternalNotes: IndividualInfo.internalNotes,
                Decision: "Approve",
                IsPIC1: isPIC1Falg
            }
            sendPaymentLink(request);
        }
    }
    // Handle sending clarification
    const handleClearfication = () => {
        if (CFComments === "" || CFComments === undefined || CFComments === null) {
            return error("The comments field for clarification the application is mandatory and must be filled out.", "error")
        }
        let request = {
            IndividualMembersId: editID,
            ClarificationDetails: CFComments,
            IsCorporate: false,
            IsAcademic: false
        }
        confirm("Clarification", "Are you sure you want to send the clarification?", "Yes", "Cancel")
            .then((confirmed) => {
                if (confirmed) {
                    sendClearfication(request)
                }
            });
    }
    // Handle form clear action
    const handleClear = () => {
        const updatedForm = { ...formData };
        updatedForm.initialState.acraFile = null;
        SetFormData(updatedForm);
    }
    // Handle file download
    const handleDownload = async () => {
        let request = {
            type: "ACRA_FILE",
            fileName: fileUploadData?.fileName || acraFileName,
        }
        const response = await getPDF(request);
        saveAs(response?.data?.fileData, response?.data?.fileName);
    }
    // Handle dropdown change
    const handleDropdownChange = (data, dataField) => {
        const fields = ["membershipTenureId", "membershipTypeId"];
        if (fields.includes(dataField)) {
            setMembershipPricingDetails({
                ...membershipPricingDetails,
                [dataField]: data?.value || 0
            });
        }
        if (dataField === "nationalityID") {
            let updatedForm = onHandleNationalityDropdown(data, formData);
            SetFormData(updatedForm);
        }
    };
    //APPROVE BTN
    const handleApprove = () => {
        if (memberDetails.isUpgradeUnderAppOrCpp && memberDetails.isUpgrade) {
            let formResponse = formRef.current.getFormData();
            if (!formResponse) {
                return error("Please fill all mandatory fields.", "")
            }
            setIsSendPaymentLink(true);
            handleUpdate(formResponse)
            let request = {
                IndividualMemberId: Number(editID),
                TaskId: memberDetails?.taskId,
                CommentsToMember: formResponse?.comment
            }
            approveIndividualUpgrade(request)
        }
    };

    const formActionHandler = {
        CLEAR: handleClear,
        DOWNLOAD: handleDownload,
        DDL_CHANGED: handleDropdownChange
    }

    return (
        <div className="view-individual-member">
            <div className='row gx-5  bg-light tasklist-form'>
                {(isSuccess && isSuccessGetByID)
                    ?
                    <>
                        <IndividualMemberDataContext.Provider value={contextValue}>
                            <FormCreator
                                ref={formRef} {...formData}
                                initialState={pricingInitialStateRef.current}
                                onActionChange={formActionHandler}
                                onFormFieldValueChange={handleFormFieldChange}
                            />
                        </IndividualMemberDataContext.Provider>
                    </>
                    : <ModuleLoader />
                }
            </div>
            <div className='row mt-4'>
                <div className='col-lg-12 d-flex justify-content-between'>
                    {
                        isPIC1Falg ?
                            <>
                                <Buttons
                                    buttonText="Reject Application"
                                    buttonTypeClassName="red-btn"
                                    onClick={openModal}
                                />
                                <Buttons
                                    buttonText="Send Clarification"
                                    buttonTypeClassName="outline-btn"
                                    onClick={openCFModal}
                                />
                            </>
                            :
                            null
                    }
                    <Buttons
                        buttonText="Update Application"
                        buttonTypeClassName="outline-btn"
                        onClick={() => handleUpdate(null)}
                    />
                    {
                        getIndiviudalMembershipType === !1
                            || getIndiviudalMembershipType === !2
                            || getIndiviudalMembershipType === !3
                            || getIndiviudalMembershipType === !4
                            || getIndiviudalMembershipType === !5
                            || getIndiviudalMembershipType === 11
                            || isPIC1Falg === false
                            || isSendBackToPIC1Flag === true ?
                            <>
                                {memberDetails?.isUpgradeUnderAppOrCpp && memberDetails?.isUpgrade ?
                                    <Buttons
                                        buttonText="Approve"
                                        isLoading={approveIndividualUpgradeIsLoading}
                                        buttonTypeClassName="theme-btn"
                                        onClick={() => handleApprove()}
                                        isDisable={memberDetails?.isAuditLog ? false : true}
                                    />
                                    :
                                    <Buttons
                                        buttonText="Send Payment Link"
                                        isLoading={IsPaymentLoading || sendPaymentLinkForUpgradeIndividualLoading}
                                        buttonTypeClassName="theme-btn"
                                        onClick={handleSentPaymentLink}
                                        isDisable={memberDetails?.isAuditLog ? false : getIndiviudalMembershipType === 11 ? false : true}
                                    />
                                }
                            </>
                            :
                            <>
                                {/* {memberDetails?.isUpgradeUnderAppOrCpp && memberDetails?.isUpgrade ? */}
                                {/* <Buttons
                                        buttonText="Approve"
                                        isLoading={approveIndividualUpgradeIsLoading}
                                        buttonTypeClassName="theme-btn"
                                        onClick={() => handleApprove()}
                                    />
                                    : */}
                                <Buttons
                                    buttonText="Send for Approval"
                                    buttonTypeClassName="theme-btn"
                                    onClick={handleSentForApproval}
                                    isLoading={isLoadingSentApproval}
                                    isDisable={isApprovalRequiredFlag ? true : false}
                                />
                                {/* } */}
                            </>

                    }
                </div>
            </div>
            {isModalOpen &&
                <CenterModel
                    modelTitle={'Reject Application'}
                    show={"test"}
                    handleClose={openModal}
                    onUpload={openModal}
                >
                    <Suspense fallback={<div>Loading Editor...</div>}>
                        <TinyEditor
                            onTinyMceEditorChange={(e) => setRejectComments(e)}
                        />
                        {/* <CKEditorComponent
                            onCKEditorChange={(e) => setRejectComments(e)}
                        /> */}
                        <Buttons
                            buttonText="Send Email"
                            isLoading={IsRejectedLoading}
                            buttonTypeClassName="outline-btn mt-4"
                            onClick={handleReject}
                        />
                    </Suspense>
                </CenterModel>
            }
            {isModalOpenForCF &&
                <CenterModel
                    modelTitle={'Send Clarification'}
                    show={"test"}
                    handleClose={openCFModal}
                    onUpload={openCFModal}
                >
                    <Suspense fallback={<div>Loading Editor...</div>}>
                        <TinyEditor
                            onTinyMceEditorChange={(e) => setCFComments(e)}
                        />
                        {/* <CKEditorComponent
                            onCKEditorChange={(e) => setCFComments(e)}
                        /> */}
                        <Buttons
                            buttonText="Send Email"
                            isLoading={IsCFLoading}
                            buttonTypeClassName="outline-btn mt-4"
                            onClick={handleClearfication}
                        />
                    </Suspense>
                </CenterModel>
            }
        </div>
    )
})
export default IndividualForm;