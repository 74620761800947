import { inidividualFormData } from "../data/IndividualMemberTable.Data";
import { auditLogData } from "../data/AuditLog.Data";

export const setFieldParameter = (apiResponse) => {
    inidividualFormData.initialState.salutationsID = apiResponse?.salutationId;
    inidividualFormData.initialState.fullName = apiResponse?.firstName;
    inidividualFormData.initialState.lastName = apiResponse?.lastName;
    inidividualFormData.initialState.contact = apiResponse?.contactNo;
    inidividualFormData.initialState.howWould = apiResponse?.likeUsToAddressYou;
    inidividualFormData.initialState.isGenderMale = apiResponse?.isMale ? 'male' : 'female';
    inidividualFormData.initialState.nationalityID = apiResponse?.nationalityTypeId;
    inidividualFormData.initialState.countryOfResID = apiResponse?.countryOfResidenceId;
    inidividualFormData.initialState.residentStatus = apiResponse?.residentStatusId;
    inidividualFormData.initialState.race = apiResponse?.raceId;
    inidividualFormData.initialState.bwEmailAddress = apiResponse?.businessEmail;
    inidividualFormData.initialState.awEmailAddress = apiResponse?.personalEmail;
    inidividualFormData.initialState.linkedinProfile = apiResponse?.linkedinProfile;
    inidividualFormData.initialState.billingCategory = apiResponse?.individualMemberBillingCategoryId;
    inidividualFormData.initialState.updatedBy = apiResponse?.firstName;
    inidividualFormData.initialState.subscriptionStartDate = apiResponse?.subscriptionStartDate;
    inidividualFormData.initialState.subscriptionEndDate = apiResponse?.subscriptionEndDate;
    inidividualFormData.initialState.subscriptionLastDate = apiResponse?.updatedDate;
    inidividualFormData.initialState.membershipTypeId = apiResponse?.membershipTypeId;
    inidividualFormData.initialState.membershipTenureId = apiResponse?.membershipTenureId;
    inidividualFormData.initialState.isStartMembershipOn = apiResponse?.isStartMemberShip ? true : false;
    inidividualFormData.initialState.startMembershipDate = apiResponse?.startMembershipOn;
    inidividualFormData.initialState.paymentTerms = apiResponse?.paymentTermsId || 1;
    inidividualFormData.initialState.comment = apiResponse?.commentstomember;
    //upgrade membership initials
    inidividualFormData.initialState.currentMembershipTier = apiResponse?.currentMembershipType;
    inidividualFormData.initialState.newMembershipTier = apiResponse?.membershipTypeId;
    inidividualFormData.initialState.subscriptionEndDateForUpgrade = apiResponse?.subscriptionEndDate;

    const navigatePathIndex = inidividualFormData.formFields.findIndex(field => field.id === "navigatePath");
    if (navigatePathIndex !== -1) {
        const updatedFormData = { ...inidividualFormData };
        // Update the containerCss value for periodTo field
        updatedFormData.formFields[navigatePathIndex].fieldSetting.identifyNumber = apiResponse.individualMemberId;
    }
};

export const setPricingDetailsField = (apiResponse) => {
    inidividualFormData.initialState.entrenceFee = apiResponse?.applicationFee || 0;
    inidividualFormData.initialState.annualFee = apiResponse?.annualFee || 0;
    inidividualFormData.initialState.currentTierApplicationFee = apiResponse?.currentTierApplicationFee || 0; // Get from membership payment
    inidividualFormData.initialState.currentTierAnnualFee = apiResponse?.currentTierAnnualFee || 0; // Get from membership payment
    inidividualFormData.initialState.newTierApplicationFee = apiResponse?.newTierApplicationFee || 0; // Get from prising table
    inidividualFormData.initialState.newTierAnnualFee = apiResponse?.newTierAnnualFee || 0; // Get from prising table
    inidividualFormData.initialState.annualFeePaing = apiResponse?.annualFee || 0; // get from Neew sp
    inidividualFormData.initialState.applicationFeePaing = apiResponse?.applicationFee || 0; // get from Neew sp
    return inidividualFormData
}

export const setAuditLogField = (apiResponse) => {
    let getPIC1Name = apiResponse?.auditLogList ? apiResponse.auditLogList[0].updateByUser : "";
    auditLogData.initialState.taskId = apiResponse?.taskId || 0;
    auditLogData.initialState.PIC1Name = getPIC1Name || "";
    auditLogData.initialState.applicantName = `${apiResponse?.firstName} ${apiResponse?.lastName} ` || "";
    auditLogData.initialState.internalNotes = "";
    return auditLogData
}

export const resetInternalNotes = (apiResponse) => {
    auditLogData.initialState.internalNotes = "";
    return auditLogData
}

// export const changesWhenisUpgradeUnderAppOrCpp = (formData) => {
//     let form = { ...formData }

//     // form.formFields = form.formFields.filter((obj) => obj.id !== "seprator2" && obj.id !== "auditLog" && obj.id !== "auditLogComponent" && obj.id !== "isStartMembershipOn" && obj.id !== "startMembershipDate" && obj.id !== "membershipTenureId" && obj.id !== "br1" && obj.id !== "br2" && obj.id !== "br3")

//     return { ...form }
// }

export const changesWhenisUpgradeIndividualMember = (formData) => {
    let form = { ...formData }

    form.formFields = form.formFields.filter((obj) => obj.id !== "currentMembershipTier" && obj.id !== "currentTierApplicationFee" && obj.id !== "currentTierAnnualFee" && obj.id !== "newMembershipTier" && obj.id !== "newTierApplicationFee" && obj.id !== "newTierAnnualFee" && obj.id !== "subscriptionEndDateForUpgrade" && obj.id !== "annualFeePaing" && obj.id !== "applicationFeePaing" && obj.id !== "upgradeMembershipDetails" && obj.id !== "upgradeSeperator")

    return { ...form }
}

export const onHandleNationalityDropdown = (dataField, formData) => {
    const residentStatusIndex = formData.formFields.findIndex(field => field.id === "residentStatus");
    const isHideAndRemoveValidation = dataField.value !== 1 ? true : false;
  
    const newCssValue = isHideAndRemoveValidation ? "col-md-6 mb-3" : "col-md-6 mb-3 d-none";
    const newValidationValue = isHideAndRemoveValidation ? [{ type: "require" }] : [];
    const updatedFormData = { ...formData };
    if (residentStatusIndex !== -1) {
      updatedFormData.formFields[residentStatusIndex].style.containerCss = newCssValue;
      updatedFormData.formFields[residentStatusIndex].validation = newValidationValue;
    }
    return { ...updatedFormData };
  }
