export const Menu = [
  // {
  //   name: "Dashboard",
  //   id: "dashboard",
  //   to: "/Dashboard",
  //   iconClass: "bi bi-speedometer2",
  //   subMenu: false,
  //   children: [],
  // },
  {
    name: "Account Management",
    id: "AccountManagement",
    iconClass: "bi bi-person-circle",
    subMenu: true,
    children: [
      {
        id: "CreateAdminAccount",
        submenuName: "Create Account",
        to: "/CreateAdminAccount",
      },
      {
        id: "ViewEditAdminAccount",
        submenuName: "View/Edit Account",
        to: "/ViewEditAdminAccount",
      },

    ],
  },

  {
    name: "Membership",
    id: "membership",
    iconClass: "bi bi-people",
    subMenu: true,
    children: [
      {
        id: "membershipPricing",
        submenuName: "Membership Pricing",
        to: "/membershipPricing",
      },
      {
        id: "membershipTaskListCorporate",
        submenuName: "Membership Task List (Corporate)",
        to: "/membershipTaskListCorporate",
      },
      {
        id: "membershipTaskListIndividual",
        submenuName: "Membership Task List (Individual)",
        to: "/membershipTaskListIndividual",
      },
      {
        id: "membershipTaskListAcademic",
        submenuName: "Membership Task List (Academic)",
        to: "/membershipTaskListAcademic",
      },
      {
        id: "viewAcademicMembers",
        submenuName: "View Academic Member",
        to: "/viewAcademicMembers",
      },
      {
        id: "viewcorporateMembers",
        submenuName: "View Corporate Member",
        to: "/viewCorporateMembers",
      },
      {
        id: "viewIndividualMembers",
        submenuName: "View Individual Member",
        to: "/viewIndividualMembers",
      },
      {
        id: "membershipReport",
        submenuName: "Membership Report",
        to: "/membershipReport",
      },


    ],
  },
  {
    name: "Email Management",
    id: "emailManagement",
    iconClass: "bi bi-briefcase",
    subMenu: true,
    children: [
      {
        id: "emailCampaign",
        submenuName: "EDM Campaign",
        to: "/EmailManagement",
      },
      {
        id: "nonMemberEDM",
        submenuName: "Non-Member EDM",
        to: "/EDMUploadMember",
      },
    ],
  },
  {
    name: "Financial",
    id: "financial",
    iconClass: "bi bi-people",
    subMenu: true,
    children: [
      {
        id: "paymentReconciliation",
        submenuName: "Payment Reconciliation",
        to: "/PaymentReconciliation",
      },

    ],
  },
  {
    name: "Field Maintenance",
    id: "fieldMaintenance",
    iconClass: "bi bi-people",
    subMenu: true,
    children: [
      {
        id: "dropdownManagement",
        submenuName: "Dropdown Management",
        to: "/DropdownManagement",
      },
      {
        id: "emailTemplateManagement",
        submenuName: "Email Template Management",
        to: "/EmailTemplateManagement",
      },
    ],
  },


];
